<template>
  <div class="my-tickets">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="content-path">
          <breadcrumbs :crumbs="breadcrumbs"/>
        </div>

        <div class="title-container">
          <div class="left-title-container">
            <h3 class="past-title">Events</h3>
            <FormulateInput
              :class="{ 'active': isDisplayAll && isCurrentEvents }"
              @click="displayCurrentEvents"
              type="button"
              label="Current Events"
            />
            <FormulateInput
              :class="{ 'active': isDisplayAll && isPastEvents }"
              @click="displayPastEvents"
              type="button"
              label="Past Events"
            />
            <FormulateInput
              :class="{ 'active': type == 'REFUNDED'}"
              @click="displayRefundedEvents"
              type="button"
              label="Refunded"
            />
          </div>
          <div class="right-title-container">
            <FormulateInput
              class="typeEvent formulateInput"
              v-model="type"
              :options="typeList"
              type="select"
              placeholder="Type"
              @input="getFilteredTickets"
            />
            <FormulateInput
              class="formulateInput"
              v-model="month"
              :options="monthList"
              type="select"
              placeholder="Month"
              @input="getFilteredTickets"
            />
            <FormulateInput
              class="formulateInput formulate-input year"
              v-model="year"
              :options="yearList"
              type="select"
              placeholder="Year"
              @input="getFilteredTickets"
            />
            <font-awesome-icon
              class="reset-icon fa-1x"
              icon="times"
              @click="resetFilter"
            />
          </div>
          <div class="row">
            <div class="col full">
              <loader site="buyers" :active="loading"/>
            </div>
          </div>
          <div class="row" v-if="!loading">
            <div class="col full">
              <div
                class="events-wrapper"
                v-if="displayedTickets.length > 0"
              >
                <div class="events">
                  <event-ticket
                    v-for="(ticket, ticketIndex) in displayedItems"
                    v-bind:key="ticketIndex"
                    class="tickets"
                    :image="`${service_url}${ticket.event_data[0].images.hero}`"
                    :startDate="ticket.start"
                    :purchased="ticket.order_date"
                    :event="ticket.event_data[0]"
                    :invoice="ticket.invoice"
                    :endDate="ticket.end"
                    :ticket="ticket.tickets.filter((t) => !t.product)"
                    :orderId="ticket._id"
                    :item="ticket"
                    :plannerPaysFees="
                      ticket.event_data[0].buyerOrPlannerPaysFees
                    "
                    :isSpecialEvent="ticket.special_event"
                  />
                </div>
                <div class="customPaginate">
                  <paginate
                    :current-page="currentPage"
                    :total-pages="totalPages"
                    @page-change="changePage">
                  </paginate>
                </div>
              </div>
              <p v-else>No Events</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import EventTicket from "./account/EventTicket.vue";
import Loader from "@/components/Loader.vue";
import OtherEvents from "./events/OtherEvents";
// import BulletPointBox from "@/components/BulletPointBox.vue";
import InlineMessageBox from "@/components/InlineMessageBox.vue";
import TabTote from "@/components/TabTote.vue";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Paginate from "../components/Pagination.vue";

const MONTHS = [
  {value: "all", label: "All"},
  {value: "January", label: "January"},
  {value: "February", label: "February"},
  {value: "March", label: "March"},
  {value: "April", label: "April"},
  {value: "May", label: "May"},
  {value: "June", label: "June"},
  {value: "July", label: "July"},
  {value: "August", label: "August"},
  {value: "September", label: "September"},
  {value: "October", label: "October"},
  {value: "November", label: "November"},
  {value: "December", label: "December"},
];

const TYPES = [
  {value: "ORDERS", label: "Purchases"},
  {value: "COMPS", label: "Comps"},
  {value: "ATTENDEE", label: "Added as Attendee"},
  {value: "GUESTLIST", label: "Guest Lists"},
  {value: "REFUNDED", label: "Refunded"},
];
library.add(faTimes);

export default {
  name: "my-tickets",
  components: {
    EventTicket,
    Breadcrumbs,
    OtherEvents,
    Loader,
    // BulletPointBox,
    InlineMessageBox,
    TabTote,
    FontAwesomeIcon,
    Paginate
  },
  head() {
    return {
      title: "Never Lose Your Tickets Again • My Purchases • INCREDEVENT",
      meta: [
        {
          name: "description",
          content: `Never lose a ticket again! INCREDEVENT stores tickets under "My Purchases" in your tote bag. Register for a free account today!`,
        },
        {
          name: "keywords",
          content: "Events, Tickets, Directory, Promotion",
        },
        {
          name: "author",
          content: "INCREDEVENT",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
      ],
    };
  },
  data() {
    return {
      title: "My Purchases",
      breadcrumbs: [
        ["Event Directory", "/events"],
        ["My Purchases", null],
      ],
      monthList: MONTHS,
      month: null,
      year: null,
      yearList: [],
      type: "ORDERS",
      typeList: TYPES,
      data: [],
      loading: true,
      service_url: process.env.VUE_APP_IMAGE_URL,
      displayedTickets: [],
      ticketYearsPastEvents: [],
      ticketYearsUpcomingEvents: [],
      refundedTickets: [],
      displayedSimilarEvents: [],
      pastEvents: [],
      upcomingEvents: [],
      city: "",
      eventNames: [],
      currentDate: "",
      currentYear: null,
      currentMonth: null,
      pastYears: [],
      upcomingYears: [],
      show: false,
      attendeeId: "",
      checkPlannerPolicy: false,
      toteSwapLink: "",
      email: null,
      initiate: true,
      currentPage: 1,
      isCurrentEvents: true,
      isPastEvents: false,
      isDisplayAll: true
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    totalPages() {
      if (this.displayedTickets.length > 0) {
        return Math.ceil(this.displayedTickets.length / 6);
      }
      return 1;
    },
    displayedItems() {
      const startIndex = (this.currentPage - 1) * 6;
      const endIndex = startIndex + 6;
      if (this.displayedTickets.length > 0) {
        return this.displayedTickets.slice(startIndex, endIndex);
      }
      return [];
    },
  },
  async mounted() {
    try {
      this.loading = true;
      if (!this.$store.state.user.isAuthenticated) {
        this.$router.push({
          path: "/login-register",
          query: {redirect: this.$route.fullPath},
        });
      } else {
        await this.$axios.post("/auth/verify").then(({data}) => {
          this.$store.state.user.first_name = data.user.first_name;
          this.$store.state.user.last_name = data.user.last_name;
          this.email = data.user.email;
          const currentDate = new Date();
          const startYear = new Date(data.user.created_at).getFullYear();
          const currentYear = currentDate.getFullYear();
          this.month = MONTHS[0].value;
          const years = Array.from(
            {length: currentYear - startYear + 2},
            (_, i) => currentYear - i + 1
          );
          years.forEach((y) => {
            this.yearList.push({label: y, value: y});
          });
          this.year = currentYear.toString();
          this.currentYear = this.year;
          this.currentMonth = this.month;
        });
        this.loading = false;
        this.initiate = false;
        this.getTickets();
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
    },
    resetFilter() {
      this.month = null;
      this.year = null;
      this.isDisplayAll = false;
    },
    getFilteredTickets() {
      if (this.initiate) {
        return;
      }
      this.isDisplayAll = false;
      if (this.month == null) {
        this.month = this.currentMonth;
      }
      if (this.year == null) {
        this.year = this.currentYear;
      }
      this.isCurrentEvents = false;
      this.pastEvents = false;
      this.getTickets();
    },
    displayAllEvents() {
      this.isDisplayAll = true;
      this.getTickets();
    },
    displayCurrentEvents() {
      this.type = "ORDERS";
      this.isDisplayAll = true;
      this.isCurrentEvents = true;
      this.isPastEvents = false;
      this.getTickets(true);
    },
    displayPastEvents() {
      this.type = "ORDERS";
      this.isDisplayAll = true;
      this.isCurrentEvents = false;
      this.isPastEvents = true;
      this.getTickets();
    },
    displayRefundedEvents() {
      this.type = 'REFUNDED';
      this.isCurrentEvents = false;
      this.isPastEvents = false;
      this.getTickets();
    },
    async getTickets() {
      this.loading = true;
      this.currentPage = 1;
      this.ticketYearsUpcomingEvents = [];
      let email = "";
      await this.$axios.post("/auth/verify").then(({data}) => {
        email = data.user.email;
      });
      const {data} = await this.$axios.post("/tickets/get-my-tickets", {
        year: this.year,
        month: this.month,
        type: this.type,
        current: this.isCurrentEvents,
        past: this.isPastEvents,
        isDisplayAll: this.isDisplayAll,
      });

      this.displayedTickets = data.tickets;
      //this.getLocation();
      for (let x = 0; x < this.displayedTickets.length; x++) {
        if (this.displayedTickets[x].event_data.length != 0) {
          this.displayedTickets[x].start = new Date(
            +this.displayedTickets[x].event_data[0].start.$date.$numberLong
          );
          this.displayedTickets[x].end = new Date(
            +this.displayedTickets[x].event_data[0].end.$date.$numberLong
          );
        }
      }

      // sorts tickets into order based on date
      this.displayedTickets.sort((a, b) => {
        if (a.start < b.start) {
          return 1;
        } else if (a.start > b.start) {
          return -1;
        }
        return 0;
      });

      this.loading = false;

      this.$store.dispatch(
        "updateHelpVideoSrc",
        "https://youtube.com/embed/s-XuN3PguYg"
      );
      // return result;
    },
    getLocation() {
      const cookies = document.cookie.split(";");
      let locationCookie = cookies.find((c) => c.startsWith("location="));
      const self = this;
      Promise.resolve()
        .then(async function (reason) {
          if (
            !locationCookie ||
            locationCookie === "location=" ||
            locationCookie.split("-").length !== 3
          ) {
            await self.$axios.post("/users/location").then(function (response) {
              const data = response.data;
              const cookie = `location=${data.city}-${data.region_name}-${data.country_name}; path=/`;
              document.cookie = locationCookie = cookie;
              self.city = data.city;
            });
          } else {
            self.$data.city = locationCookie.split("=")[1].split("-")[0];
          }
        })
        .then(function (response) {
          const locationInfo = locationCookie.split("=")[1].split("-");
          // getting a list of the current events in my orders to prevent duplication in other events
          self.pastEvents.forEach(function (order) {
            self.eventNames.push(order.event_name);
          });

          self.$axios
            .post("/events/city-events", {
              city: self.city,
              events: self.eventNames,
            })
            .then(function (response) {
              self.currentDate = new Date();
              // self.cityOtherEvents = response.data;
              response.data.forEach(function (event) {
                var eventDate = event.start.$date;
                var mynewdate = Object.values(eventDate);
                var newDateInt = parseInt(mynewdate[0], 10);

                if (
                  newDateInt > self.currentDate &&
                  self.cityOtherEvents.length < 3
                ) {
                  self.cityOtherEvents.push(event);
                }
              });
            });
        });
    },
  },
  beforeDestroy() {
    this.$store.dispatch("updateHelpVideoSrc", null);
  },
};
</script>

<style lang="less">
.typeEvent {
  right: 0;
  top: 0;
  left: 0;
}

.bullet-point {
  h3 {
    margin: 12px auto;
  }
}
</style>

<style lang="less" scoped>
// ::v-deep {
//   .formulateInput {
//     .dropdown-outer {
//       .dropdown {
//         // padding: 17px;
//         .icon-down {
//           right: 3px;
//           top: 20px;
//         }
//       }
//     }
//   }
// }

.my-tickets {
  // height: 100vh;
  background-color: var(--content-background);
}

.bullet-point {
  background-color: var(--secondary-golden);
  border: 2px solid var(--primary-golden);
  width: 60%;
  margin: auto;
  @media screen and (max-width: 600px) {
    width: 90%;
  }
}

#Content .content-inner {
  .past-title,
  .upcoming-title {
    color: var(--primary-golden);
    font-weight: 600;
    margin: 10px 10px 10px 0 !important;
  }

  .events {
    // margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 12px;
    row-gap: 12px;
    margin: 0;
    margin-top: 10px;
    position: relative;
    width: 100%;

    .year {
      grid-column-start: 1;
      grid-column-end: -1;
      color: var(--primary-golden);
      font-weight: 600;
      width: 100%;
      margin: 0;
      // position: absolute;
      // top: -30px;
      // left: 8px;
      font-size: 15px;
    }

    .tickets {
      position: relative;
    }
  }

  @media screen and (max-width: 1045px) {
    .events {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (max-width: 800px) {
    .events {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 600px) {
    .events {
      grid-template-columns: repeat(auto-fit, minmax(280px, 100%));
    }
  }

  .creation-path {
    .link {
      color: #878787;
    }

    color: #878787;
    margin: auto;
    padding: 25px 15px;
    max-width: 1800px;
  }

  .display-warning {
    color: var(--primary-green);
    margin-bottom: 23px;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 15px 0;

    .left-title-container {
      display: flex;
      align-items: center;
      width: 49%;

      .formulate-input {
        margin: 0;
        text-align: -webkit-right;
        margin-right: 10px;
        width: auto;
        //flex-basis: 0;
        //flex-grow: 1;
        //max-width: 100%;
        &.active {
          &::v-deep button {
            background: var(--secondary-blue);
          }
        }
      }

      h3 {
        margin: 0;
        white-space: nowrap;
        flex-basis: 0;
        max-width: 100%;
      }
    }

    .right-title-container {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 51%;

      .formulate-input {
        margin: 0;
      }

      .year {
        width: 50%;
      }

      .reset-icon {
        margin-right: 8px;
        margin-bottom: 3px;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 950px) {
      .left-title-container,
      .right-title-container {
        width: 100%;
        margin: 8px 0;
      }

      .left-title-container {
        .formulate-input {
          text-align: -webkit-left;
        }
      }
    }
    @media screen and (max-width: 500px) {
      .left-title-container {
        flex-wrap: wrap;

        .formulate-input {
          flex-basis: auto;
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
